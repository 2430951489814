export const configs = {
  SMIMS: {
    entity: 'SMIMS',
    env: 'dev',
    name: 'Smu-Dev',
    navs: ['Withdrawal', 'Documents', 'Transactions', 'Offer Letter', 'Onboarding', 'Counselling', 'Interview Slots'],
    socialLinks: {
      facebook: 'https://www.facebook.com/smims.smu.edu.in/',
      twitter: 'https://x.com/manipal_sikkim?lang=en',
      linkedin: 'https://www.linkedin.com/school/sikkim-manipal-institute-of-medical-sciences-smims-/',
      insta: 'https://www.instagram.com/smims_official/',
      youtube: 'https://www.youtube.com/@sikkimmanipaluniversityoff6692',
    },
    config: {
      url: 'apply-smims.smu.edu.in',
      apiBaseUrl: 'https://smims-admissions-prod-api.ken42.com/api/',
      tokenUrl: 'https://az-token-dev.ken42.com/v1/azure/token',
      logoURL: 'https://inazstgpfs3001.blob.core.windows.net/assets/SMU/SMIMS%20logo%20print%201%20(1).jpg',
    },
    id: '674c727c78db1db1e5647980',
  },
  SMIT: {
    entity: 'SMIT',
    env: 'dev',
    name: 'Smu-Dev',
    navs: ['Withdrawal', 'Documents', 'Transactions', 'Offer Letter', 'Onboarding', 'Interview Slots'],
    socialLinks: {
      facebook: 'https://www.facebook.com/SMIT.SMU',
      twitter: 'https://x.com/smitmanipal',
      linkedin: 'https://www.linkedin.com/company/sikkim-manipal-institute-of-technology-smit/',
      insta: 'https://www.instagram.com/official.smit/',
      youtube: 'https://youtube.com/@sikkimmanipalinstituteofte8487',
    },
    config: {
      url: 'smu-admissions.ken42.com',
      apiBaseUrl: 'https://smu-prod-api.ken42.com/api/',
      tokenUrl: 'https://az-token-dev.ken42.com/v1/azure/token',
      logoURL: 'https://flamestorageken42.blob.core.windows.net/flame-storage-ken42/SMIT%20LOGO%20NEW%20WHITE%20(1)%201%20(4).png',
    },
    id: '674c727c78db1db1e5647980',
  },
};