import React from 'react';
import { Typography, Grid, Tooltip, useTheme, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    color: ' #505F79',
    fontWeight: 500,
  },
  optional: {
    fontSize: '9px',
    fontWeight: 500,
  },
  labelWrap: {
    marginBottom: 6,
  },
  asterisk: {
    color: 'red',
  },
}));
export default function KenTextLabel(props) {
  const classes = useStyles();
  const { label, required, optionalLabel, helperText } = props;
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.labelWrap}>
      <Grid container justify="space-between" alignItems={"center"} spacing={0.5}>
        <Grid item>
          <Typography className={`${classes.label} ${props.labelClassName ? props.labelClassName : ''}`}>
            {label} {required ? <span className={classes.asterisk}>*</span> : ''}
            {helperText && (
              <span>
                <Tooltip title={helperText} enterTouchDelay={0}>
                  <HelpOutlineIcon
                    sx={{
                      height: isMobileScreen ? '16px' : '12px',
                      width: isMobileScreen ? '16px' : '12px',
                      marginLeft: '5px',
                      marginBottom: '-2px',
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.optional} variant="subtitle1">
            {!required && optionalLabel ? optionalLabel : ' '}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
