import React, { useEffect, useReducer } from 'react';
import { getAxiosInstance } from '../Utils/apiServices';
import { configs } from '../Utils/constant';
import { Navigate, useLocation } from 'react-router-dom';
import KenLoader from '../Components/KenLoader';

export const initialState = {
  logo: null,
  config: {},
  entityname: null,
  navs: [],
  socialLinks: {},
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'RESET':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export const EntityContext = React.createContext();

export const EntityContentProvider = (props) => {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem('user'));

  const [state, dispatch] = useReducer(reducer, initialState);

  const { pathname, state: locationState } = useLocation();

  useEffect(() => {
    const pathEntity = locationState?.org ? locationState.org : pathname ? pathname : null;
    if (pathEntity) {
      const hasConfigs = Object.keys(configs).find((items) => {
        return pathEntity.toUpperCase().includes(items);
      });
      if (hasConfigs) {
        sessionStorage.setItem('entity', hasConfigs);
      }
    }
  }, [pathname, locationState, configs]);

  React.useEffect(() => {
    const getTitle = document.querySelector('title');

    let config;

    if (user) {
      const entity = sessionStorage.getItem('entity');
      const userEntities = Object.keys(user);

      for (let i = 0; i < userEntities.length; i++) {
        const element = userEntities[i];
        const customEntity = element === entity ? element : entity;
        config = configs[customEntity];
        sessionStorage.setItem('entity', customEntity);
        getTitle.innerText = customEntity;
      }
    } else {
      const pathEntity = locationState?.org ? locationState.org : pathname ? pathname : null;
      const hasConfigs = Object.keys(configs).find((items) => {
        return pathEntity.toUpperCase().includes(items);
      });
      if (hasConfigs) {
        config = configs[hasConfigs];
        getTitle.innerText = hasConfigs;
      } else {
        config = configs['SMIMS'];
        getTitle.innerText = 'SMIMS';
      }
    }

    if (!config) {
      <Navigate to={'*'} />;
      return;
    }

    getAxiosInstance(config);
    dispatch({
      type: 'UPDATE',
      payload: {
        logo: config.config.logoURL,
        config: { ...config.config },
        entityname: config.entity,
        navs: [...config.navs],
        socialLinks: { ...config.socialLinks },
      },
    });
  }, [location]);

  return (
    <EntityContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {state?.entityname ? props.children : <KenLoader />}
    </EntityContext.Provider>
  );
};

export const useEntityContext = () => {
  const context = React.useContext(EntityContext);
  if (!context) {
    throw new Error('useEntityContext within provider');
  }
  return context;
};
