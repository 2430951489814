const routes = {
  login: { smims: '/smims-login', smit: '/smit-login' },
  signup: { smims: '/smims-signup', smit: '/smit-signup' },
  loginOTP: { smims: '/smims-login-otp', smit: '/smit-login-otp' },
  verifyEmail: { smims: '/smims-verify-email', smit: '/smit-verify-email' },
  verifiedOTP: { smims: '/smims-otp-verified', smit: '/smit-otp-verified' },
  leadForm: '/smit-lead',
  newApplication: 'form',
  home: 'home',
  document: 'document',
  applications: 'applications',
  myApplications: 'my-applications',
  feePayments: 'feePayments',
  history: 'history',
  offerLetter: 'offerLetter',
  onboardQues: 'onboardQues',
  interviewSlots: 'interviewSlots',
  interview: 'interview',
  councelling: 'counselling',
  councellingDetails: 'counsellingDetails',
};
export default routes;
