import { Box, CssBaseline, useMediaQuery } from '@mui/material';
import React, { Suspense, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import routes from '../../Constants/routes';
import { useTheme } from '@emotion/react';
import { SnackbarProvider } from 'notistack';
import { ErrorBoundary } from 'react-error-boundary';
import { TABLET_BREAKPOINT } from '../../Constants/constant';
import { ErrorFallback } from '../../Utils/ErrorBoundary';
import Withdrawal from '../Withdrawal';
import { KenPublicRoute, KenPrivateRoute } from '../../Components/KenRoute';

const HomePage = React.lazy(() => import('../HomePage'));
const MenuAppBar = React.lazy(() => import('../../Components/MenuAppBar'));
const DocumentPage = React.lazy(() => import('../DocumentPage'));

const Login = React.lazy(() => import('../Auth/Login'));
const SignUp = React.lazy(() => import('../Auth/SignUp'));
const LoginOTP = React.lazy(() => import('../Auth/Login/LoginOTP'));
const VerifyEmail = React.lazy(() => import('../Auth/SignUp/VerifyEmail'));
const VerifiedOTP = React.lazy(() => import('../Auth/SignUp/VerifiedOTP'));

const LoginSMIMS = React.lazy(() => import('../Auth2/Login'));
const SignUpSMIMS = React.lazy(() => import('../Auth2/SignUp'));
const LoginOTPSMIMS = React.lazy(() => import('../Auth2/Login/LoginOTP'));
const VerifyEmailSMIMS = React.lazy(() => import('../Auth2/SignUp/VerifyEmail'));
const VerifiedOTPSMIMS = React.lazy(() => import('../Auth2/SignUp/VerifiedOTP'));

const ApplicationForm = React.lazy(() => import('../ApplicationForm'));
const MyApplications = React.lazy(() => import('../MyApplications'));
const FeePayment = React.lazy(() => import('../FeePayment'));
const ApplicationFormNew = React.lazy(() => import('../ApplicationForm/Application'));
const ThankYouPage = React.lazy(() => import('../../Components/KenApplicationForm/Components/ThankYouPage'));
const FeeStructure = React.lazy(() => import('../FeePayment/Components/FeeStructure'));
const FeeDetails = React.lazy(() => import('../FeePayment/Components/FeeDetails'));
const SideNavigation = React.lazy(() => import('../../Components/SideNavigation'));
const OfferLetter = React.lazy(() => import('../OfferLetter'));
const OfferLetterDetailed = React.lazy(() => import('../OfferLetter/Components/OfferLetterDetailed'));
const OnboardQues = React.lazy(() => import('../OfferLetter/Components/OnboardQues'));
const InterviewSlots = React.lazy(() => import('../Interview'));
const Interview = React.lazy(() => import('../Interview/Interview'));
const Councelling = React.lazy(() => import('../Councelling'));
const CouncellingDetails = React.lazy(() => import('../Councelling/CouncellingDetails'));

export default function MainLayout() {
  const theme = useTheme();
  const screenSize = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(TABLET_BREAKPOINT));

  const location = useLocation();
  const isForm = location.pathname === '/form' || location.pathname === '/application';
  const isApplication = localStorage.getItem('formId');
  const user = JSON.parse(localStorage.getItem('user'));

  const [open, setOpen] = React.useState(true);
  const [swipeableDrawer, setSwipeableDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const NotFound = () => {
    return <h1>Not Found</h1>;
  };

  const VerifyEmailSMIMSRender = () => {
    const getSmims = user ? user['SMIMS'] : false;
    if (getSmims) {
      if (isApplication) {
        return <Navigate to={`/application?formId=${isApplication}&type=undefined`} />;
      }
    } else {
      return <VerifyEmailSMIMS />;
    }
  };

  const VerifyEmailSMITRender = () => {
    const getSmims = user ? user['SMIT'] : false;
    if (getSmims) {
      if (isApplication) {
        return <Navigate to={`/application?formId=${isApplication}&type=undefined`} />;
      }
    } else {
      return <VerifyEmail />;
    }
  };

  const VerifyOTPSMITRender = () => {
    const getSmit = user ? user['SMIT'] : false;
    if (getSmit) {
      if (isApplication) {
        return <Navigate to={`/application?formId=${isApplication}&type=undefined`} />;
      } else {
        return <Navigate to={'/home'} />;
      }
    } else {
      return <LoginOTP />;
    }
  };

  const VerifyOTPSMIMSRender = () => {
    const getSmit = user ? user['SMIMS'] : false;
    if (getSmit) {
      if (isApplication) {
        return <Navigate to={`/application?formId=${isApplication}&type=undefined`} />;
      } else {
        return <Navigate to={'/home'} />;
      }
    } else {
      return <LoginOTPSMIMS />;
    }
  };

  const MainFrame = ({ children }) => {
    return (
      <Box display={'flex'} height={'100%'}>
        <CssBaseline />
        <SideNavigation
          open={open}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
          setSwipeableDrawer={setSwipeableDrawer}
          swipeableDrawer={swipeableDrawer}
          setOpen={setOpen}
        />
        <MenuAppBar open={open} handleDrawerOpen={handleDrawerOpen} setSwipeableDrawer={setSwipeableDrawer} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            margin: isMobileScreen ? '0px' : screenSize ? '80px - 28px' : '80px 16px',
            height: 'calc(100% - 160px)',
            width: isMobileScreen ? '100%' : 'auto',
            paddingTop: isMobileScreen ? '65px' : 'auto',
            maxWidth: !isMobileScreen ? (open ? 'calc(100% - 260px)' : 'calc(100% - 145px)') : 'auto',
          }}
        >
          {children}
        </Box>
      </Box>
    );
  };

  return (
    <Suspense fallback={ErrorFallback}>
      <div style={!isForm ? { height: '100%' } : {}}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            window.location.reload();
          }}
        >
          <SnackbarProvider autoHideDuration={10000}>
            <Routes>
              <Route path={'/smit'} element={<Navigate to={routes.login.smit} />} />
              <Route path={'/smims'} element={<Navigate to={routes.login.smims} />} />
              <Route path={'*'} element={<NotFound />} />

              {/* SMIT Auth's */}
              <Route
                path={routes.login.smit}
                element={
                  <KenPublicRoute entity={'SMIT'}>
                    <Login />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.signup.smit}
                element={
                  <KenPublicRoute entity={'SMIT'}>
                    <SignUp />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.loginOTP.smit}
                element={
                  <KenPublicRoute entity={'SMIT'}>
                    <VerifyOTPSMITRender />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.verifiedOTP.smit}
                element={
                  <KenPublicRoute entity={'SMIT'}>
                    <VerifiedOTP />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.verifyEmail.smit}
                element={
                  <KenPublicRoute entity={'SMIT'}>
                    <VerifyEmailSMITRender />
                  </KenPublicRoute>
                }
              />

              {/* SMIMS Auth's */}
              <Route
                path={routes.login.smims}
                element={
                  <KenPublicRoute entity={'SMIMS'}>
                    <LoginSMIMS />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.signup.smims}
                element={
                  <KenPublicRoute entity={'SMIMS'}>
                    <SignUpSMIMS />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.loginOTP.smims}
                element={
                  <KenPublicRoute entity={'SMIMS'}>
                    <VerifyOTPSMIMSRender />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.verifiedOTP.smims}
                element={
                  <KenPublicRoute entity={'SMIMS'}>
                    <VerifiedOTPSMIMS />
                  </KenPublicRoute>
                }
              />
              <Route
                path={routes.verifyEmail.smims}
                element={
                  <KenPublicRoute entity={'SMIMS'}>
                    <VerifyEmailSMIMSRender />
                  </KenPublicRoute>
                }
              />

              {/* Private Routes */}
              <Route
                path={'/form'}
                element={
                  <KenPrivateRoute>
                    <Box display={'flex'} height={'100%'}>
                      <CssBaseline />
                      <Box
                        sx={{
                          margin: '0px',
                          height: 'calc(100% - 160px)',
                          width: '100%',
                        }}
                      >
                        <ApplicationForm />
                      </Box>
                    </Box>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={'/application'}
                element={
                  <KenPrivateRoute>
                    <Box display={'flex'} height={'100%'}>
                      <CssBaseline />
                      <Box
                        sx={{
                          margin: '0px',
                          height: 'calc(100% - 160px)',
                          width: '100%',
                        }}
                      >
                        <ApplicationFormNew />
                      </Box>
                    </Box>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/home`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <HomePage />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/feePayments`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <FeePayment />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/feeStructure`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <FeeStructure />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/feeDetails`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <FeeDetails />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />

              <Route
                path={`/document`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <DocumentPage />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/thankYou`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <ThankYouPage />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/${routes.councelling}`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <Councelling />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/${routes.councellingDetails}`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <CouncellingDetails />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/feePayments`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <FeePayment />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/courses`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <Navigate to={'/home'} />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/${routes.myApplications}`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <MyApplications />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/offerLetter`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <OfferLetter />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/withdrawal`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <Withdrawal />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/offerLetterDetailed`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <OfferLetterDetailed />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/${routes.onboardQues}`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <OnboardQues />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/interviewSlots`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <InterviewSlots />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
              <Route
                path={`/interview`}
                element={
                  <KenPrivateRoute>
                    <MainFrame>
                      <Interview />
                    </MainFrame>
                  </KenPrivateRoute>
                }
              />
            </Routes>
          </SnackbarProvider>
        </ErrorBoundary>
      </div>
    </Suspense>
  );
}
