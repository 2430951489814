import { useTheme } from '@emotion/react';
import { Box, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import KenDialog from '../../Components/KenDialog';
import KenInput from '../../Components/KenInput';
import KenLoader from '../../Components/KenLoader';
import PaymentCard from '../../Components/PaymentCard';
import { REGEX, TABLET_BREAKPOINT } from '../../Constants/constant';
import { AuthContext } from '../../Context/authContext';
import { createUpdateApplication, getApplicationsByApplicantId, getApplicationByApplicationId } from '../../Utils/apiServices';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  bg: {
    background: theme.palette.card.backgroundColor,
  },
  titleContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      background: 'white',
      padding: theme.spacing(2),
    },
  },
  cardContainer: {
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  },
  title: {
    textTransform: 'uppercase',
    display: 'inline-block',
    paddingBottom: '5px',
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '55px',
      height: '4px',
      bottom: 0,
      left: '0%',
      borderBottom: '4px solid #F6C445',
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      'font-style': 'normal',
      'font-weight': '500',
      'font-size': '18px',
      'line-height': '100%',
      color: theme.palette.KenColors.neutral900,
    },
  },
}));
export default function Withdrawal() {
  const classes = useStyles();
  const [loading, setLoader] = useState();
  const [cardData, setCardData] = useState([]);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [withdrawalData, setWithdrawalData] = useState({
    reason: null,
    acountName: null,
    branchAndBank: null,
    accountNumber: null,
    ifscCode: null,
    comment: null,
  });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState();
  const [appData, setAppData] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setDialog] = useState();
  const [flag, setFlag] = useState(true);
  const navigate = useNavigate();
  const {
    state: { user },
  } = useContext(AuthContext);
  const { register, control, handleSubmit } = useForm({ mode: 'all' });

  useEffect(() => {
    const { applicantId } = user;
    if (user) {
      setLoader(true);
      getApplicationsByApplicantId(applicantId)
        .then((res) => {
          if (res?.data) {
            let dataCopy = [];
            res?.data.forEach((el) => {
              if (el?.Application_Status__c === 'Withdrawal') {
                dataCopy.push(el);
              }
            });
            setCardData(dataCopy);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoader(false);
        });
    }
  }, [user, flag]);

  const handleData = (formData) => {
    let flag = false;
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (key !== 'comment') {
          if (formData[key] === null) {
            flag = true;
          }
        }
      }
    }
    if (flag === true) {
      return enqueueSnackbar('Please fill the mandatory fields', {
        variant: 'warning',
      });
    }
    let dataCopy = { ...data };
    console.log(dataCopy, 'f43r34r34t');
    dataCopy.A_c_Number__c = formData.accountNumber;
    dataCopy.Account_Name__c = formData.accountHolderName;
    dataCopy.Reason_For_Withdrawal__c = formData.reason;
    dataCopy.Comments__c = formData.comment;
    dataCopy.IFSC_Code__c = formData.ifscCode;
    dataCopy.Bank_Branch__c = formData.bankBranch;
    dataCopy.Sub_Status__c = 'In Progress';
    setLoader(true);
    createUpdateApplication({ application: dataCopy })
      .then((res) => {
        if (res.success) {
          setLoader(false);
          setDialog(false);
          enqueueSnackbar('The data has been successfully sent', {
            variant: 'success',
          });
          navigate('/home');
          setFlag(!flag);
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar('something went wrong', {
          variant: 'error',
        });
      });
  };

  const callApplication = (item) => {
    setDialog(true);
    if (item && item.Sub_Status__c !== 'Initiated') {
      setLoader(true);
      getApplicationByApplicationId(item.Id)
        .then((res) => {
          let appData = res.data.application;
          setAppData(appData);
          setLoader(false);
          setDisableConfirm(true);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  return (
    <Box>
      {loading && <KenLoader />}
      <Box mb={2} p={1} className={`${isSmall ? classes.bg : ''} ${classes.titleContainer}`}>
        <Grid container item xs={12}>
          <Grid item xs={12} textAlign="left">
            <Typography variant="h4" fontWeight={500} mb={2} className={classes.title}>
              Withdraw Application
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} className={classes.cardContainer}>
        {loading ? (
          // Loader component or any other loading indicator
          <KenLoader />
        ) : cardData.length > 0 ? (
          cardData?.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={3} maxWidth={`${theme.spacing(56)} !important`}>
                <PaymentCard
                  handleViewDetails={() => {
                    setData(item);
                    callApplication(item);
                  }}
                  {...item}
                  field={'Withdrawn'}
                  buttonTitle={item.Sub_Status__c === 'Initiated' ? 'Withdraw Request' : 'View Request'}
                  formName={item?.k42_Academic_Application__r?.Form_Title__c}
                  formNo={item?.Application_Number__c}
                />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h5" sx={{ marginLeft: '32px' }}>
            No Withdrawal Found
          </Typography>
        )}
      </Grid>
      <KenDialog
        open={dialog}
        handleClose={() => {
          setDialog(false);
        }}
        dialogActions={true}
        hidePositiveButton={true}
        handleClickOpen={() => {
          setDialog(false);
        }}
        onClose={() => {
          setDialog(false);
        }}
        maxWidth={'md'}
        hideNegativeButton={true}
      >
        <Typography
          style={{
            display: 'flex',
            fontWeight: '600',
            fontSize: '20px',
            marginTop: '20px',
          }}
        >
          Withdraw Application
        </Typography>
        <form onSubmit={handleSubmit(handleData)}>
          <Grid container spacing={2} mt={2}>
            <Grid item md={6}>
              <Controller
                name="accountHolderName"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <KenInput
                      required={true}
                      label="Account Holder name"
                      value={appData?.Account_Name__c || value}
                      defaultValue={appData?.Account_Name__c}
                      error={error?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
                {...register('accountHolderName', {
                  required: 'Field is required',
                  maxLength: {
                    value: 80,
                    message: 'Field length cannot be more than 80 characters',
                  },
                  pattern: {
                    value: REGEX.NAME,
                    message: 'Please enter a valid account holder name',
                  },
                })}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="bankBranch"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <KenInput
                      required={true}
                      label="Bank & Branch"
                      value={appData?.Bank_Branch__c || value}
                      error={error?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
                {...register('bankBranch', {
                  required: 'Field is required',
                  maxLength: {
                    value: 120,
                    message: 'Field length cannot be more than 120 characters',
                  },
                  pattern: {
                    value: REGEX.NAME,
                    message: 'Please enter a valid bank branch',
                  },
                })}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="accountNumber"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <KenInput
                      required={true}
                      label="Account Number"
                      value={appData?.A_c_Number__c || value}
                      error={error?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
                {...register('accountNumber', {
                  required: 'Field is required',
                  pattern: {
                    value: /^[0-9]{9,18}$/,
                    message: 'Please enter a valid account number',
                  },
                })}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="ifscCode"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <KenInput
                      required={true}
                      label="IFSC Code"
                      value={appData?.IFSC_Code__c || value}
                      error={error?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
                {...register('ifscCode', {
                  required: 'Field is required',
                  pattern: {
                    value: /^[A-Z]{4}0[A-Z0-9]{6}$/gm,
                    message: 'Please enter valid IFSC Code',
                  },
                })}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="reason"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <KenInput
                      required={true}
                      label="Reason for Withdrawal"
                      value={appData?.Reason_For_Withdrawal__c || value}
                      error={error?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
                {...register('reason', {
                  required: 'Field is required',
                  maxLength: {
                    value: 180,
                    message: 'Field length cannot be more than 180 characters',
                  },
                })}
              />
            </Grid>
            <Grid item md={6}>
              <Controller
                name="comment"
                control={control}
                render={({ field: { value, onChange }, fieldState: { error } }) => {
                  return (
                    <KenInput
                      required={false}
                      label="Comment"
                      value={appData?.Comments__c || value}
                      error={error?.message}
                      onChange={(e) => {
                        onChange(e);
                      }}
                    />
                  );
                }}
                {...register('comment', {
                  // required: 'Field is required',
                  maxLength: {
                    value: 180,
                    message: 'Field length cannot be more than 180 characters',
                  },
                })}
              />
            </Grid>
          </Grid>
          {!disableConfirm ? (
            <Box display={'flex'} justifyContent={'end'}>
              <Button
                style={{
                  margin: '10px 5px',
                  color: '#fff',
                  backgroundColor: '#092682',
                  borderRadius: '32px',
                  '&:hover': {
                    cursor: 'pointer',
                    background: '#DFE8FF',
                    color: '#505F79',
                  },
                  padding: '13px 52px',
                  fontSize: 14,
                  marginBottom: 16,
                  textTransform: 'capitalize',
                }}
                color="primary"
                type="submit"
                variant="contained"
              >
                <Typography>Confirm</Typography>
              </Button>
            </Box>
          ) : null}
        </form>
      </KenDialog>
    </Box>
  );
}
