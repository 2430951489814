import React, { useEffect, useReducer } from 'react';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN } from '../Constants/constant';

export const initialState = {
  isAuthenticated: false,
  user: null,
  search: null,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      const isLoginInp = action.payload.user?.accountName;
      const data = isLoginInp
        ? {
            ...(state.user ? state.user : JSON.parse(localStorage.getItem('user'))),
            [action.payload.user?.accountName]: action.payload.user,
          }
        : action.payload.user;
      localStorage.setItem('user', JSON.stringify(data));
      const access_token = action?.payload?.user?.accessToken?.accessToken?.token;
      const refresh_token = action?.payload?.user?.refreshToken?.refreshToken?.token;

      if (access_token && refresh_token) {
        localStorage.setItem(KEY_ACCESS_TOKEN, access_token);
        localStorage.setItem(KEY_REFRESH_TOKEN, refresh_token);
      }
      return {
        ...state,
        isAuthenticated: true,
        user: data,
        search: action.payload.search,
        programSearch: action.payload.programSearch,
      };
    case 'LOGOUT':
      let newData = { ...state };
      const entityLogout = sessionStorage.getItem('entity');
      delete newData['user'][entityLogout];
      
      if (!newData?.user || Object.keys(newData?.user).length === 0) {
        localStorage.clear();
        return {
          ...state,
          isAuthenticated: false,
          user: null,
        };
      }
      localStorage.setItem('user', JSON.stringify(newData?.user));
      return { ...newData };
    default:
      return state;
  }
};

export const AuthContext = React.createContext();

export const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  //TODO: Enable for loggin in user once token is store in local storage
  useEffect(() => {
    const user = JSON?.parse(localStorage?.getItem('user'));
    if (user) {
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
        },
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext within provider');
  }
  return context;
};
