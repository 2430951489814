import './App.css';
import React from 'react';
import MainLayout from './Containers/MainLayout';
import { AuthContextProvider } from './Context/authContext';
import ThemeProvider from './Utils/themeProvider';
import { I18nextProvider } from 'react-i18next';
import i18n from './Utils/i18next/i18n.js';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-datepicker/dist/react-datepicker.css';
import { AppContextProvider } from './Context/appContext';
import { FormContextProvider } from './Context/formContext';
// import { Worker } from '@react-pdf-viewer/core';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { EntityContentProvider } from './Context/entityContext.js';
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import { init, reactRouterV6BrowserTracingIntegration, replayIntegration } from '@sentry/react';

try {
  init({
    dsn: 'https://a57ef6263b5fbcb7ea59639eac3c758d@o4506744673861632.ingest.us.sentry.io/4508800153747456',
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [/^https:\/\/apply\.smu\.edu\.in/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} catch (error) {
  console.error('Sentry initialization error:', error);
}

function App() {
  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppContextProvider>
          <FormContextProvider>
            <AuthContextProvider>
              <EntityContentProvider>
                <ThemeProvider>
                  <I18nextProvider i18n={i18n}>
                    <div className="App">
                      <MainLayout />
                    </div>
                  </I18nextProvider>
                </ThemeProvider>
              </EntityContentProvider>
            </AuthContextProvider>
          </FormContextProvider>
        </AppContextProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
