import axios from 'axios';
import { COUNTRIES_API_KEY, COUNTRIES_BASE_URL, KEY_ACCESS_TOKEN, KEY_AZURE_TOKEN, KEY_REFRESH_TOKEN, REFRESH_URL } from '../Constants/constant';
import * as Sentry from '@sentry/react';

let appConfig;
export let axiosInstance;
// const configUrl = KEY_CONFIG_URL;

export const getAxiosInstance = (data) => {
  if (!data) {
    window.location.reload();
    return;
  }

  appConfig = data?.config;
  axiosInstance = axios.create({
    baseURL: appConfig.apiBaseUrl,
    timeout: 25000,
  });

  axiosInstance.interceptors.request.use((req) => {
    const token = localStorage.getItem(KEY_AZURE_TOKEN);
    req.headers['entity'] = data?.entity;
    req.headers['access-token'] = localStorage.getItem(KEY_ACCESS_TOKEN);
    req.headers.Authorization = `Bearer ${token}`;
    return req;
  });

  //Response interceptor for API calls
  axiosInstance.interceptors.response.use(
    (resp) => {
      return resp;
    },
    async function (error) {
      console.error('ApiService: Interceptor: ', error);
      Sentry.captureException(error);
      if (!error.response?.config && error.response?.status !== 401) {
        return error;
      }
      return axios({
        method: 'get',
        baseURL: `${appConfig.tokenUrl}`,
      }).then(async (response) => {
        if (localStorage.refresh_token && (error.response.status === 402 || error.response.status === 502 || error.response.status === 401)) {
          const url = REFRESH_URL;
          try {
            const res = await axios.post(
              url,
              {
                refreshToken: localStorage.refresh_token,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.azure_token}`,
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              }
            );
            localStorage.setItem(KEY_ACCESS_TOKEN, res?.data?.accessToken?.token);
            localStorage.setItem(KEY_REFRESH_TOKEN, res?.data?.refreshToken?.token);
            localStorage.setItem(KEY_AZURE_TOKEN, response.data.access_token);
            error.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
            error.response.config.headers['access-token'] = res.data.accessToken.token;
            return axios(error.response.config);
          } catch (err) {
            localStorage.clear();
            window.location.reload();
          }
        } else {
          localStorage.setItem(KEY_AZURE_TOKEN, response.data.access_token);
          error.response.config.headers['Authorization'] = `Bearer ${response.data.access_token}`;
          Sentry.captureException(error)
          return axios(error.response.config);
        }
      });
    }
  );
};

export const hdfcPaymentIntegartion = async (data) => {
  try {
    const path = `hdfc/payments/initiate`;
    const response = await axiosInstance.post(`${path}`, data);
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const hdfcPaymentIntegartionv2 = async (data) => {
  const path = 'hdfc/payments/initiate';
  const res = axiosInstance.post(path, data);
  return res;
};

export const getAllPrograms = async (applicantId) => {
  const path = `formbyapplicant?applicantid=${applicantId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getFormByFormId = async (formId) => {
  const path = `form/formbyid?formid=${formId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationsByApplicantId = async (applicantid) => {
  const path = `retriveapplication?applicantid=${applicantid}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getApplicationByApplicationId = async (applicationId) => {
  const path = `applicationbyid?applicationid=${applicationId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createUpdateApplication = async (data) => {
  const path = `application/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data;
};

export const deleteApplicationRecords = async (data) => {
  const delData = { data: data };
  const path = `portla/delete`;
  const res = await axiosInstance.delete(`${path}`, delData);
  return res && res.data ? res.data : null;
};

/**
 *
 *
 * Invalid URL
 *
 *
 */

export const getDocuments = async (applicantId) => {
  const path = `documentstatus?applicantid=${applicantId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const createDocuments = async (data) => {
  const path = `signup`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getNotifications = async (applicantId) => {
  const path = `notificationbyapplicant?applicantid=${applicantId}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getImportantDates = async () => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/importantdates`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getProgramDetails = async () => {
  const path = `programdetail`;

  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getBoardDetails = async () => {
  const path = `boarddetails`;

  const res = await axiosInstance.post(`${path}`);
  return res && res.data ? res.data : null;
};

export const getFeeDetails = async (data) => {
  const path = `retrivefeedetails?applicantid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postFeeCoupon = async (data) => {
  const path = `appliedcoupencode`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getOfferLetter = async (data) => {
  const path = `offerletter?applicantid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postInitatePayment = async (data) => {
  const path = `initiate_payment`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getFeeReceipt = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalfee/retrieve/receipt?transactionid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getQuestionare = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/questionarie?contactid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postQuestionare = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/save`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getTransactionDetailsByTransactionId = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/portalfee/retrieve/transaction?paymentid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postAppliedCouponCode = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/AppliedCouponCodeAPI`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};
export const postUploadDocument = async (data) => {
  const path = `/uploadDocument`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getRenderingDetails = async (data) => {
  const path = `renderingdetails?applicantid=${data}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

// Not Done
export const getApplicantByEmail = async (email) => {
  const path = `verify/email?email=${email}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getDocumentsViaRecordId = async (id) => {
  const path = `documentbyrecordid?recordid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getCouncellingApplications = async (id) => {
  const path = `/counsellingapplications?applicantid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const applicationPreviewPdf = async (applicationId) => {
  const path = `application/preview?applicationid=${applicationId}`;

  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getSpecializationPreferences = async (id) => {
  const path = `salesforce?endpoint=/services/apexrest/courseSpecializations`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getSpecializationPreferencesII = async (id) => {
  const path = `/specializationpreferences?applicationprogramid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const saveCouncelling = async (payload) => {
  const path = `/portalcounselling/save`;
  const res = await axiosInstance.post(`${path}`, payload);
  return res && res.data ? res.data : null;
};

export const getOfferletterViaApplicationId = async (id) => {
  const path = `offerletterbyapplication?applicationid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const getInterviewSlotsViaApplicationId = async (id) => {
  const path = `salesforce?endpoint=services/apexrest/interview/retrieve/slottimings?applicationid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

// Not Done
export const postSaveSlots = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/interview/save/slot`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

// Not Done
export const postSavePreferences = async (data) => {
  const path = `save/preference`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

// Not Done
export const postInterviewSlots = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/interview/save/slot`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

// Not Done
export const postInterviewStudentRequestedSlots = async (data) => {
  const path = `salesforce?endpoint=services/apexrest/interview/save/studentRequestedSlot`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const getOldApplicationData = async (id) => {
  const path = `salesforce?endpoint=services/apexrest/portal/retrieve/oldApplication?applicationid=${id}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

export const postManualFeePayment = async (data) => {
  const path = `manualfeepayment`;
  const res = await axiosInstance.post(`${path}`, data);
  return res && res.data ? res.data : null;
};

export const postSignUpWithoutContact = async (email, phone) => {
  const path = `onetimepassword?email=${email}&phone=${phone}`;
  const res = await axiosInstance.post(`${path}`, {});
  return res && res.data ? res.data : null;
};

export const getVerfiyOTPWithoutContact = async (phone, otp) => {
  const path = `verifyonetimepassword?contact=${phone}&otp=${otp}`;
  const res = await axiosInstance.get(`${path}`);
  return res && res.data ? res.data : null;
};

const axiosinstancecountries = axios.create({
  baseURL: COUNTRIES_BASE_URL,
  headers: {
    accept: 'application/json',
    'X-CSCAPI-KEY': COUNTRIES_API_KEY,
  },
});

export const getCountries = async () => {
  const path = 'v1/countries';
  const res = await axiosinstancecountries.get(path);
  return res && res.data ? res.data : null;
};

export const getStates = async (iso) => {
  const path = `v1/countries/${iso}/states`;
  const res = await axiosinstancecountries.get(path);
  return res && res.data ? res.data : null;
};

export const getCities = async (iso, siso) => {
  const path = `v1/countries/${iso}/states/${siso}/cities`;
  const res = await axiosinstancecountries.get(path);
  return res && res.data ? res.data : null;
};

export const updateLead = async (id, status) => {
  const path = `update/lead?contact=${id}&status=${status}`;
  const res = await axiosInstance.post(`${path}`);
  return res && res.data ? res.data : null;
};
