import { Navigate } from 'react-router-dom';
import { useEntityContext } from '../../Context/entityContext';
import { useAuthContext } from '../../Context/authContext';
import routes from '../../Constants/routes';

export function KenPublicRoute({ children, entity }) {
  const { state: getEntity } = useEntityContext();
  const localEntity = sessionStorage.getItem('entity');
  const customEntity = getEntity?.entityname ? getEntity?.entityname : localEntity;
  const { state: getUser } = useAuthContext();
  const { isAuthenticated, user } = getUser;

  if (!isAuthenticated) {
    if (localEntity === entity && getEntity?.entityname === entity) {
      return children;
    } else {
      return <Navigate to={`/${entity.toLowerCase()}`} />;
    }
  } else {
    if (user[customEntity]?.accountName !== entity) {
      return children;
    } else {
      return <Navigate to={'/home'} />;
    }
  }
}

export function KenPrivateRoute({ children }) {
  const { state: getEntity } = useEntityContext();
  const { state: getUser } = useAuthContext();
  const { user, isAuthenticated } = getUser;

  const publicroute = routes.login[getEntity?.entityname.toLowerCase()];

  if (!isAuthenticated) {
    return <Navigate to={publicroute} />;
  }

  if (user[getEntity?.entityname]) {
    return children;
  }

  return <Navigate to={publicroute} />;
}
